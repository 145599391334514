<template lang="pug">
	.info-tooltip
		.d-block.mb-2
			span.b3.text-grey(ref='id') {{ $t('project.id') }}:
			span.b3.ml-1(ref='id_value' v-html="item.id")
		.d-block.mb-2
			span.b3.text-grey(ref='status') {{ $t('project.status') }}:
			span.b3.ml-1(ref='status_value' v-html="status(item)")
		.d-block.mb-2
			span.b3.text-grey(ref='framed') {{ $t('project.framed') }}:
			span.b3.ml-1(ref='framed_value') {{ item.createdAt | dateDDMMMYYYY }}
		.d-block.mb-2
			span.b3.text-grey(ref='follower') {{ $t('project.follower') }}:
			span.b3.ml-1(ref='follower_value')
				span(v-if='item.user' v-html="item.user.username")
		.d-block.mb-2
			span.b3.text-grey(ref='email') {{ $t('user.email') }}:
			span.b3.ml-1(ref='email_value' v-html="item.email")
		.d-block.mb-2
			span.b3.text-grey(ref='title') {{ $t('plan.title') }}:
			span.b3.ml-1(ref='title_value' v-html="item.plan.title")
		.d-flex.flex-wrap.mb-2
			span.b3.text-grey.mr-1(ref='price') {{ $t('forms.price') }}:
			template(ref='price_value')
				span.b3.mr-2.text-nowrap(v-html="item.price.priceWithCurrency()")
				span.b3.mr-2.text-grey.text-through.text-nowrap(v-if='item.promotion || item.promoCode' v-html="item.plan.price.priceWithCurrency()")
				span.mr-2.info-tooltip__discount(v-if='item.promotion') -{{item.promotion.discount}}%
				span.mr-2.info-tooltip__discount(v-if='item.promoCode') -{{item.promoCode.discount}}%
				span.text-success.info-tooltip__discount(v-if='item.extraPrice' v-html='`+${item.extraPrice.priceWithCurrency()}`')
		.d-block.mb-2(v-if='item.promotion')
			span.b3.text-grey(ref='promotion') {{ $t('paymentConfirm.promotion') }}:
			span.b3.ml-1(ref='promotion_value' v-html="item.promotion.title")
		.d-block.mb-2(v-if='item.promoCode')
			span.b3.text-grey(ref='promoCode') {{ $t('project.promoCode') }}:
			span.b3.ml-1(ref='promoCode_value' v-html="item.promoCode.code")
		.d-block.mb-2
			span.b3.text-grey.mr-1(ref='paidTo') {{ $t('project.paidTo') }}:
			span(ref='paidTo_value')
				span.b3.mr-1(v-if='item.kickAt') {{ item.kickAt | dateDDMMMYYYY}}
				span.b3.mr-1(v-else-if='item.kickedAt') {{ item.kickedAt | dateDDMMMYYYY}}
				span.b3.mr-1(v-else-if='item.nextAt') {{ item.nextAt | dateDDMMMYYYY}}
				span.b3(v-else-if='item.plan.isForever()') {{ $t('plan.periods.forever')}}
		.d-block.mb-2
			span.b3.text-grey.text-nowrap.mr-1(ref='next') {{ $t('project.nextPayment') }}:
			.d-flex(ref='next_value')
				span.b3.mr-1.text-nowrap(:class="{'mb-2': item.isStatusTrial()}" v-if='(item.nextAt && item.nextAt == item.kickAt && !item.isOnSupportCriticalAndUnknown()) && !item.isOneTime()') {{ item.nextAt | dateDDMMMYYYY }}
				span.b3.mr-2.text-nowrap(v-if='item.isOnSupport() || item.isOnSupportCriticalAndUnknown()' v-html="$t('plan.nextPaymentInSupport')")
				span.b3.mb-0.info-tooltip__tooltip(v-if='item.isOnSupport() || item.isOnSupportCriticalAndUnknown()' :id="!isMobile ? `nextPaymentNull-${item.id}` : ''" @click="isMobile ? $bvModal.show(`nextPaymentNull-${item.id}`) : ''" style='margin-top: -2px;') ?
					.info-tooltip__above-tooltip(v-if="!isMobile")
						p.b4.info-tooltip__next-txt(v-html="$t('tutorials.recoverySubscriptionDesc')")
						pw-btn-link(:text="`<div class='status-dollar-circle bg-white mr-1'></div>&nbsp${$t('tutorials.recoverySubscription')}`" :href="'https://docs.paywall.pw/rus/obshee/vosstanovlenie-podpisok'" :blank="true" :tooltip="true")
				span.b3.mb-0(v-if='item && item.isCompletedSubscription() || item.nextAt == null && item.hasPaymentPaid() && !item.isOnSupport() || item.isOneTime()') {{ $t('plan.nextPaymentNull') }}
		.d-block.mb-2(v-if='item.counters && item.counters.count')
			span.b3.text-grey(ref='paymentsCount') {{ $t('project.paymentsCount') }}:
			span(ref='paymentsCount_value')
				span.b3.ml-1(v-html="paymentsAmountRu(item.counters.count)")
				span.b3.text-primary.ml-2.info-tooltip__link(@click="goToPayments") {{ $t('project.show') }}
		.d-block.mb-2
			span.b3.text-grey(ref='profit') {{ $t('project.profit') }}:
			p(ref='profit_value')
				span.b3(v-if='item.getProfitMerchatOtherValue() != 0' v-html="`🇷🇺 &nbsp;${item.getProfitMerchatOther()}`")
				span.b3(v-if='item.getProfitMerchatWorld2ruValue() != 0' v-html="`🌍 &nbsp;${item.getProfitMerchatWorld2ru()}`" :class='{"ml-3" : item.getProfitMerchatOtherValue() != 0}')
				span.b3(v-if='item.getProfitMerchatOtherValue() == 0 && item.getProfitMerchatWorld2ruValue() == 0' v-html="`${item.price.currency == 'RUB' ? '🇷🇺' : '🌍'} &nbsp;0&nbsp;${currency[item.price.currency]}`")
		.btn.btn-border.btn-small.mt-3(@click='copy') {{ $t('button.copyData') }}
</template>

<script>
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import {SERVICE_STRING} from '@/utils/string';
import {CURRENCY} from '@/models/money';

export default {
	name : 'InfoTooltip',
	components: {
		PwBtnLink
	},
	props: {
		item: {
			type: Object,
			default: (() => {})
		}
	},
	computed: {
		currency() {
			return CURRENCY;
		},
	},
	methods: {
		status(item) {
			if( item.isUndefined() ) return this.$t('statusesFollowers.unknown');
			if( item.isStatusTrial() ) return this.$t('statusesFollowers.trial');
			if( item.isActive() ) return this.$t('statusesFollowers.active');
			if( item.isCompleted() || item.isCancelledButNotKicked() || item.isCompletedSubscription() ) return this.$t('statusesFollowers.closed');

			return '';
		},
		paymentsAmountRu(v) {
			return v + ' ' + SERVICE_STRING.ru_ends(v, this.$t('project.paymentsArray'));
		},

		goToPayments() {
			this.$router.push({name: 'finance', query: {type: 'payments', search: this.item.id}});
		},
		copy () {
			let text = [];
			let id = [];
			let value = [];

			_.each(this.$refs, (r, i) => i.includes('_value') ? value.push(r.innerText) : id.push(r.innerText));
			_.each(id, (v, i) => text.push(`${v} ${value[i]}`));

			this.$copyText(text.join('\n'))
				.then( e => this.$notify("success", this.$t('success.dataCopied')))
				.catch( e => this.$notify("error", this.$t('error.dataCopied'), e));
		},
	}
}
</script>
